import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import { motion } from 'framer-motion'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as locks from '@rushplay/compliance/locks'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from './configuration'
import * as Constants from './constants'
// import * as Cookies from './cookies-module'
import * as Icons from './icons'
import * as Player from './player'
import { Balance } from './balance'
import { Button } from './button'
import { ClientType } from './constants'
import { MenuItem } from './menu-item'
import { MenuNotificationBadge } from './menu-notification-badge'
import { MyAccountMenuItem } from './my-account-menu-item'
import { PromotionsMenuItem } from './promotions-menu-item'
import { ScrollLock } from './scroll-lock'
import { useMenuQueries } from './use-menu-queries'
import { useRaffleCampaigns } from './raffle-page/use-fetch-raffle-campaigns'

const Logotype = styled('div')`
  ${css({
    width: ['103px', '177px'],
    height: ['22px', '38px'],
    mt: ['0px', null, '16px'],
    backgroundImage: ['url(/images/logo-small.svg)', 'url(/images/logo.svg)'],
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  })};
`

const Wrapper = styled.header`
  ${css({
    backgroundColor: 'nav',
    position: 'sticky',
    top: '0px',
    left: '0px',
    height: [null, null, 'var(--window-inner-height, 100vh)'],
    width: ['100%', null, '200px'],
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: 'high',
  })};

  flex-shrink: 0;

  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${t.color('nav')};
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

const Menu = styled(motion.div)`
  ${css({
    flexGrow: 1,
    px: ['24px', '0px'],
    mt: ['40px', '0px'],
    flexDirection: 'column',
    justifyContent: [null, null, 'space-between'],
    height: ['calc(var(--window-inner-height, 100vh) - 46px)', null, 'auto'],
    overflowY: ['auto', null, 'initial'],
  })};

  -webkit-overflow-scrolling: touch;

  // These styles are only to override the animation styles from small screen
  ${t.mediaQuery.md`
    display: inline-flex !important;
    transform: translate(0) !important;
    opacity: 1 !important;
  `}

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${t.color('nav')};
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

const variants = {
  open: {
    y: '0%',
    opacity: 1,
    transition: {
      x: { type: 'spring', stiffness: 200, damping: 25 },
      default: {
        duration: 0.2,
      },
    },
    display: 'inline-flex',
  },
  closed: {
    y: '-100%',
    opacity: 0,
    transition: {
      duration: 0.2,
    },
    transitionEnd: {
      display: 'none',
    },
  },
}

export function MainMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const { authenticated } = Herz.Auth.useSession()
  const location = ReactRouter.useLocation()
  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const isMobile = ClientType.MOBILE_BROWSER === clientType

  const {
    depositQuery,
    loginQuery,
    registerQuery,
    withdrawalQuery,
  } = useMenuQueries()

  const vipLevel = ReactRedux.useSelector(state =>
    Player.getVipLevel(state.player)
  )
  const locked = ReactRedux.useSelector(
    state => locks.isInitialized(state.locks) && locks.hasLocks(state.locks)
  )
  const translate = Herz.I18n.useTranslate(
    () => [`vip.perks.table.level.${Constants.ConvertedTiers[vipLevel]}.image`],
    [vipLevel]
  )

  const isRaffleEnabled = ReactRedux.useSelector(state =>
    Configuration.isRaffleEnabled(state.configuration)
  )

  const isRaffleLinkEnabled = ReactRedux.useSelector(state =>
    Configuration.isRaffleLinkEnabled(state.configuration)
  )

  const { isRunning } = useRaffleCampaigns()

  React.useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  return (
    <Wrapper data-testid="main-menu">
      {isMenuOpen && !Constants.isDesktop && <ScrollLock />}
      {/* Topbar */}
      <Common.Box
        color="g-text"
        py={[0, null, 1]}
        px={['16px', null, '0px']}
        backgroundColor="nav"
        position="sticky"
        top="0px"
        left="0px"
        zIndex="high"
        display="flex"
        justifyContent={['space-between', null, 'stretch']}
        alignItems={['center', null, 'stretch']}
        flexDirection={['row', null, 'column']}
        flexShrink="0"
      >
        <Common.Box display="flex" justifyContent="center">
          <ReactRouter.Link to="/" data-testid="main-menu-logotype">
            <Logotype title="bitdreams.com" />
          </ReactRouter.Link>
        </Common.Box>
        <Common.Flex>
          <Common.Box
            flexGrow="1"
            flexDirection={['row-reverse', null, 'column']}
            display="flex"
          >
            {!authenticated && (
              <Common.Box display={['flex', 'flex', 'none']}>
                <Common.Box pr={0}>
                  <ReactRouter.Link to={`?${loginQuery}`}>
                    <Button
                      variant="secondary"
                      stretch
                      small
                      fontSize={['11px', 3]}
                      style={{
                        border: isMobile ? '1px solid' : '3px solid',
                      }}
                    >
                      {translate('main-menu.login')}
                    </Button>
                  </ReactRouter.Link>
                </Common.Box>
                <Common.Box>
                  <ReactRouter.Link to={`?${registerQuery}`}>
                    <Button small fontSize={['11px', 3]} variant="primary">
                      {translate('main-menu.register')}
                    </Button>
                  </ReactRouter.Link>
                </Common.Box>
              </Common.Box>
            )}
            {authenticated && (
              <ReactRouter.Link
                to={`?${depositQuery}`}
                data-testid="main-menu-balance"
              >
                <Common.Box mx={['0px', '8px']} mt={['0px', null, '16px']}>
                  <Balance />
                </Common.Box>
              </ReactRouter.Link>
            )}
          </Common.Box>
          <Common.Box
            data-testid="main-menu-menu-toggler"
            display={['inline-flex', null, 'none']}
            alignItems="center"
            pl={0}
            fontSize="29px"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <Icons.Clear />
            ) : (
              <MenuNotificationBadge>
                <Icons.Menu />
              </MenuNotificationBadge>
            )}
          </Common.Box>
        </Common.Flex>
      </Common.Box>

      {/* Navigations */}
      <Menu
        variants={variants}
        animate={isMenuOpen ? 'open' : 'closed'}
        initial="closed"
      >
        <nav>
          {isRaffleEnabled && isRaffleLinkEnabled && isRunning ? (
            <MenuItem icon={Icons.BankNote} testId="raffle-page" to="/raffle">
              {translate('main-menu.raffle')}
            </MenuItem>
          ) : null}

          <MenuItem
            disabled={locked}
            icon={Icons.GamesMenu}
            testId="main-menu-casino"
            to="/casino"
          >
            {translate('main-menu.casino')}
          </MenuItem>
          <MenuItem
            disabled={locked}
            icon={Icons.LiveCasinoMenu}
            testId="main-menu-live-casino"
            to="/live-casino"
          >
            {translate('main-menu.live-casino')}
          </MenuItem>
          <PromotionsMenuItem
            disabled={locked || !authenticated}
            testId="main-menu-promotion-notifications"
          >
            {translate('main-menu.promotion-notifications')}
          </PromotionsMenuItem>
          <MyAccountMenuItem
            disabled={locked || !authenticated}
            testId="main-menu-account"
          >
            {translate('main-menu.account')}
          </MyAccountMenuItem>
          <MenuItem
            disabled={locked}
            icon={Icons.SupportMenu}
            testId="main-menu-support"
            to="/faq"
          >
            {translate('main-menu.support')}
          </MenuItem>

          {authenticated && (
            <Common.Box display="grid" gridGap={1} px={0} pt={2}>
              <ReactRouter.Link to={`?${depositQuery}`}>
                <Button stretch variant="primary" fontSize={3}>
                  {translate('main-menu.deposit')}
                </Button>
              </ReactRouter.Link>
              <ReactRouter.Link to={`?${withdrawalQuery}`}>
                <Button stretch variant="secondary" fontSize={3}>
                  {translate('main-menu.withdraw')}
                </Button>
              </ReactRouter.Link>
            </Common.Box>
          )}
          {!authenticated && (
            <Common.Box
              pt={[2, 3, 4, 5]}
              px={0}
              pb={['45px', null, '0px']}
              display={['none', 'none', 'grid']}
              gridGap={1}
            >
              <ReactRouter.Link to={`?${registerQuery}`}>
                <Button variant="primary" stretch>
                  {translate('main-menu.register')}
                </Button>
              </ReactRouter.Link>
              <ReactRouter.Link to={`?${loginQuery}`}>
                <Button variant="secondary" stretch>
                  {translate('main-menu.login')}
                </Button>
              </ReactRouter.Link>
            </Common.Box>
          )}
        </nav>
        <Common.Box
          py={1}
          pb={['50px', null, 1]}
          color="g-text"
          textAlign="center"
          fontSize={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          flexShrink="0"
          fontFamily="head"
          fontWeight="bold"
        >
          {authenticated ? (
            <ReactRouter.Link to="/logout" data-testid="main-menu-log-out">
              <Common.Space p={0}>
                {translate('main-menu.log-out')}
              </Common.Space>
            </ReactRouter.Link>
          ) : (
            <Common.Box
              display={['block', null, 'none']}
              pb={1}
              px={0}
              width="100%"
            >
              {/* For returning users the primary button (the one in the
                topbar) is Login and this is a secondary one on mobile */}
              <Common.Box my={0}>
                <ReactRouter.Link
                  to={`?${registerQuery}`}
                  data-testid="main-menu-register"
                >
                  <Button
                    stretch
                    variant="primary"
                    small
                    fontSize={['11px', 3]}
                  >
                    {translate('main-menu.register')}
                  </Button>
                </ReactRouter.Link>
              </Common.Box>
              <Common.Box my={0}>
                <ReactRouter.Link
                  to={`?${loginQuery}`}
                  data-testid="main-menu-login"
                >
                  <Button
                    stretch
                    variant="secondary"
                    small
                    fontSize={['11px', 3]}
                  >
                    {translate('main-menu.login')}
                  </Button>
                </ReactRouter.Link>
              </Common.Box>
            </Common.Box>
          )}
        </Common.Box>
      </Menu>
    </Wrapper>
  )
}

Herz.I18n.Loader.preload(
  [
    'main-menu.raffle',
    'main-menu.account',
    'main-menu.login',
    'main-menu.register',
    'main-menu.my-games',
    'main-menu.casino',
    'main-menu.live-casino',
    'main-menu.horse-racing',
    'main-menu.mahjong',
    'main-menu.vip',
    'main-menu.promotion-notifications',
    'account.nav.me-moments',
    'main-menu.support',
    'main-menu.deposit',
    'main-menu.withdraw',
    'main-menu.log-out',
    'badge.new',
    'campaign-tooltip.header',
    'campaign-tooltip.body',
  ],
  MainMenu
)

// For @loadable/components
export default MainMenu
